import * as React from 'react';
import { graphql } from 'gatsby';
import { DefaultLayout } from '../layouts/DefaultLayout';
import CMSPage from '../components/Content/CMSPage';

function ImpressumPage({ data, location }) {
  const { page } = data;
  const { siteMetadata } = data.site;
  const { metadata, opengraph } = page;

  return (
    <DefaultLayout>
      <div className="wrap">
        <CMSPage content={page} />
      </div>
    </DefaultLayout>
  );
}

export default ImpressumPage;

export const ImpressumPageQuery = graphql`
  query ImpressumContent($language: String) {
    site: site {
      siteMetadata {
        title
        canonicalSiteUrl
      }
    }
    locales: allLocale(filter: { language: { eq: $language } }) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
    page: cockpitRegionImpressum(lang: { eq: $language }) {
      title
      teaser
      identifier
      cssclass
      content
      images {
        publicURL
        childImageSharp {
          gatsbyImageData
        }
      }
      metadata {
        title
        metakeywords
        metadescription
      }
      opengraph {
        title
        description
        type
        image {
          publicURL
          childImageSharp {
            gatsbyImageData
          }
        }
      }
    }
  }
`;
