// extracted by mini-css-extract-plugin
export var agb = "CMSPage-module--agb--3PunA";
export var col = "CMSPage-module--col--9GVGV";
export var content = "CMSPage-module--content--N2KvV";
export var dataprivacy = "CMSPage-module--dataprivacy--344Xm";
export var datenschutz = "CMSPage-module--datenschutz--9D6Md";
export var image = "CMSPage-module--image--k8a1R";
export var impressum = "CMSPage-module--impressum--Wf4CM";
export var teaser = "CMSPage-module--teaser--iQaCO";
export var title = "CMSPage-module--title--7jYwp";
export var ueberuns = "CMSPage-module--ueberuns--icq4y";