import React, { useTransition } from 'react';
import clsx from 'clsx';

import { PageTitle } from '../Common/PageTitle';

import * as styles from './CMSPage.module.css';
import { GatsbyImage, getImage } from 'gatsby-plugin-image';
import { useTranslation } from 'gatsby-plugin-react-i18next';
import InnerHTML from 'dangerously-set-html-content';
import { graphql, useStaticQuery } from 'gatsby';

function CMSPage({ content: pageContent, id }) {
  let { title, cssclass, content, teaser, images, identifier } = pageContent;
  const { t } = useTranslation('menu');

  const data = useStaticQuery(graphql`
    query cookieQuery {
      site {
        siteMetadata {
          cookieDeclarationScript
        }
      }
    }
  `);

  let modifiedcontent = content
    .split('src="/')
    .join('src="https://ezagrar.cms.mangomedia.at/')
    .split('class="gaOptOut"')
    .join('href="javascript:gaOptout()"');

  const cookieScript = data.site.siteMetadata.cookieDeclarationScript;

  modifiedcontent = modifiedcontent && modifiedcontent.split('{{cookiescript}}').join(cookieScript);

  let icon = '';
  if (identifier === 'ueber-uns') {
    icon = 'ueberuns';
    title = t('aboutus');
  }

  return (
    <div id={id}>
      <PageTitle title={title} icon={icon} />
      {images && (
        <div style={{ overflow: 'hidden' }}>
          <GatsbyImage image={getImage(images)} alt="" className={styles.image} />
        </div>
      )}
      {identifier === 'ueber-uns' && <div className={styles.title}>{title}</div>}
      {teaser && JSON.stringify(teaser) !== '"[object Object]"' && (
        <div className={styles.teaser}>{JSON.stringify(teaser)}</div>
      )}
      <div className={clsx(styles.content, styles[cssclass])}>
        <InnerHTML html={modifiedcontent} />
      </div>
    </div>
  );
}

export default CMSPage;
